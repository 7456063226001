<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-lg-12">
                <div class="banners--actions">
                  <div class="create">
                    <div @click="showAddPopup" class="btn btn-sm btn-success">
                      <i class="fas fa-plus"></i> {{$t('popularWords.add')}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-12">
                <table class="table" style="cursor: pointer;">
                  <thead>
                    <tr>
                      <th>{{$t('views.banners.name')}}</th>
                      <th>{{$t('views.banners.showStart')}}</th>
                      <th>{{$t('views.banners.showEnd')}}</th>
                      <th>{{$t('views.banners.bannerType')}}</th>
                      <th>{{$t('views.banners.link')}}</th>
                      <th>{{$t('views.banners.bannerPreview')}}</th>
                      <th>{{$t('views.banners.onOff')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in banners" :key="item.id">
                      <td @click="showEditPopup(item)">{{ item.name }}</td>
                      <td @click="showEditPopup(item)">{{ phpDatetimeToRus(item.start_dt) }}</td>
                      <td @click="showEditPopup(item)">{{ phpDatetimeToRus(item.stop_dt) }}</td>
                      <td @click="showEditPopup(item)">
                        <span v-if="item.type == 'simple'">{{$t('views.banners.noAction')}}</span>
                        <span v-if="item.type == 'url'">{{$t('views.banners.toExtLink')}}</span>
                        <span v-if="item.type == 'custom'">{{$t('views.banners.custom')}}</span>
                      </td>
                      <td @click="showEditPopup(item)">{{ item.url }}</td>
                      <td @click="showEditPopup(item)">
                        <img width="180" :src="item.img_url">
                      </td>
                      <td>
                        <i @click="toggleOn(item.id)" style="font-size: 20px;" v-if="item.is_on == 1" class="fas fa-circle text-success"></i>
                        <i @click="toggleOn(item.id)" style="font-size: 20px;" v-if="item.is_on == 0" class="fas fa-circle text-danger"></i>
                      </td>
                    </tr>
                  </tbody>    
                </table>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </div>

    <popup
      :closeButton="addBannerPopup.closeButton"
      :actionButton="addBannerPopup.actionButton"
      :actionClass="addBannerPopup.actionClass"
      :show="addBannerPopup.show"
      @closePopup="closeAddPopup"
      @actionPopup="addPopupSubmit"
    >
      <div slot="header">{{$t('views.banners.addBanner')}}</div>
      <div slot="body">
        <create-banner :submit="addBannerSubmit" @submitCreate="createBann"></create-banner>
      </div>
    </popup>

    <popup
      :closeButton="editBannerPopup.closeButton"
      :actionButton="editBannerPopup.actionButton"
      :actionClass="editBannerPopup.actionClass"
      :show="editBannerPopup.show"
      @closePopup="closeEditPopup"
      @actionPopup="editPopupSubmit"
    >
      <div slot="header">{{$t('views.banners.editBanner')}}</div>
      <div slot="body">
        <edit-banner 
          :setDefault="editBannerObj" 
          :submit="editBannerSubmit"
          @submitCreate="editBann"
        ></edit-banner>
      </div>
    </popup>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";


//import { minValue, maxValue, required} from "vuelidate/lib/validators";
import axios from "axios";
import Popup from '../../../components/Popup.vue';
import CreateBanner from '../../../components/management/banners/CreateBanner.vue';

import Formatter from "@/components/libraries/Formatter";
import EditBanner from '../../../components/management/banners/EditBanner.vue';
const formatter = new Formatter();
//import Paginator from "@/components/Paginator";
//import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
   // Paginator,
    Layout,
    PageHeader,
    CreateBanner,
    EditBanner
  },
  data() {
    return {
      banners: [],
      addBannerPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      addBannerSubmit: 0,

      editBannerPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      editBannerObj: {},
      editBannerSubmit: 0,

      submitted: false,
      title: this.$t('menuitems.management.list.banners'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.banners'),
          active: true
        }
      ],
      data: [],
    };
  },
  // validations: {
  //   sumPoint: { required, minValue: minValue(1), maxValue: maxValue(5000) }
  // },
  computed: {

  },
  watch: {
    
  },
  methods: {
    closeEditPopup(){
      this.editBannerPopup.show = false;
    },
    showEditPopup(obj){
      this.editBannerPopup.show = true;
      this.editBannerObj = obj;
    },
    editPopupSubmit(){
      this.editBannerSubmit++;
    },
    closeAddPopup(){
      this.addBannerPopup.show = false;
    },
    showAddPopup(){
      this.addBannerPopup.show = true;
    },
    addPopupSubmit(){
      this.addBannerSubmit++;
    },
    phpDatetimeToRus(datetime){
      return formatter.datetimeToRus(datetime);
    },
    editBann(data){
      let formData = new FormData();
      for(let key in data){
        if( key === 'file'){
          formData.append(key, data[key][0]);
        }else if( key == 'showStart' || key == 'showEnd'){
          let dtRes = formatter.jsObjectToPhpDatetime(data[key]);
          formData.append(key, dtRes);
        }else{
          formData.append(key, data[key]);
        }
      }
      axios
          .post(`/v1/banner/update`, formData)
          .then(() => {
            this.closeEditPopup();
            this.getBanners();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    toggleOn(bannerId){
      let formData = new FormData();
      formData.append('id', bannerId);
      
      axios
          .post(`/v1/banner/toggle-on`, formData)
          .then(() => {
            this.getBanners();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    createBann(data){
      let formData = new FormData();
      for(let key in data){
        if( key === 'file'){
          formData.append(key, data[key][0]);
        }else if( key == 'showStart' || key == 'showEnd'){
          let dtRes = formatter.jsObjectToPhpDatetime(data[key]);
          formData.append(key, dtRes);
        }else{
          formData.append(key, data[key]);
        }
      }
      axios
          .post(`/v1/banner/create`, formData)
          .then(() => {
            this.closeAddPopup();
            this.getBanners();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getBanners(){
      axios
          .get(`/v1/banner/get`)
          .then(resp => {
            this.banners = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getBanners();
  }
};
</script>

<style scoped lang="scss">
  .banners--actions{
    text-align: right;
  }
</style>
