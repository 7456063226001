<template>
  <div>

    <form class="needs-validation" @submit.prevent="submitCreateBanner">

      <div class="form-group">
        <label for="name">{{$t('views.banners.name')}}</label>
        <input
            id="name"
            v-model="banner.name"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.banner.name.$error }"
        />
        <div v-if="submitted && $v.banner.name.$error" class="invalid-feedback">
          <span v-if="!$v.banner.name.required">
            {{$t('validatePhrases.requiredField')}}
          </span>
          <span v-if="!$v.banner.name.minLength">
            {{$t('validatePhrases.minLength')}}: 3
          </span>
          <span v-if="!$v.banner.name.maxLength">
            {{$t('validatePhrases.maxLength')}}: 255
          </span>
        </div>
      </div>

      <div class="form-group">
        <label for="codename">{{$t('views.banners.codename')}}</label>
        <input
            id="codename"
            v-model="banner.codename"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.banner.codename.$error }"
        />
        <div v-if="submitted && $v.banner.codename.$error" class="invalid-feedback">
          <span v-if="!$v.banner.codename.required">
            {{$t('validatePhrases.requiredField')}}
          </span>
          <span v-if="!$v.banner.codename.minLength">
            {{$t('validatePhrases.minLength')}}: 3
          </span>
          <span v-if="!$v.banner.codename.maxLength">
            {{$t('validatePhrases.maxLength')}}: 60
          </span>
        </div>
      </div>

      <div class="mt-3 mb-3">
        <div>Текущее изображение</div>
        <img :src="banner.img_url" width="130">
      </div>
      
      <label>{{$t('views.banners.bannerImage')}}</label>
      <div class="custom-file">
        <input
            id="img"
            @change="previewFiles"
            type="file"
            class="custom-file-input"
        />
        <label class="custom-file-label" for="img">{{$t('popularWords.chooseFile')}}</label>
      </div>
      <ul>
        <li v-for="item in banner.file" :key="item.lastModified">{{ item.name }}</li>
      </ul>

      <div class="form-group mt-3">
        <label>{{$t('views.banners.showStart')}}</label>
        <date-picker v-model="banner.showStart" :type="'datetime'" :format="'DD.MM.YYYY HH:mm'" confirm></date-picker>
      </div>

      <div class="form-group mt-3">
        <label>{{$t('views.banners.showEnd')}}</label>
        <date-picker v-model="banner.showEnd" :type="'datetime'" :format="'DD.MM.YYYY HH:mm'" confirm></date-picker>
      </div>

      <div class="form-group mt-3">
        <label>{{$t('views.banners.placement')}}</label>
        <select class="form-control form-control-sm" v-model="banner.placement">
          <option value="homePage">homePage</option>
          <option value="documentListPage">documentListPage</option>
          <option value="orderListPage">orderListPage</option>
        </select>
      </div>


      <div class="form-group mt-3">
        <label>{{$t('views.banners.bannerType')}}</label>
        <select class="form-control form-control-sm" v-model="banner.type">
          <option value="simple">{{$t('views.banners.noAction')}}</option>
          <option value="url">{{$t('views.banners.toExtLink')}}</option>
          <option value="custom">{{$t('views.banners.custom')}}</option>
        </select>
      </div>


      <div v-if="banner.type == 'url' || banner.type == 'custom'" class="form-group">
        <label for="url">{{$t('views.banners.url')}}</label>
        <input
            id="url"
            v-model="banner.url"
            type="text"
            class="form-control form-control-sm"
        />
      </div>

      <div v-if="banner.type == 'custom'" class="form-group">
        <label for="action">{{$t('views.banners.internalAction')}}</label>
        <input
            id="action"
            v-model="banner.action"
            type="text"
            class="form-control form-control-sm"
        />
      </div>
     


    </form>
  </div>
</template>

<script>

import {maxLength, minLength, required} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

import Formatter from "@/components/libraries/Formatter";
const formatter = new Formatter();

//import axios from "axios";
//import WeekScheduler from "@/components/WeekScheduler";
//import PrinterPrice from "@/components/management/printers/PrinterPrice";
//import PrinterCustomSchedule from "./PrinterCustomSchedule.vue";
//import CopyArrayAndObject from "../../libraries/CopyArrayAndObject";

//const copyArrObj = new CopyArrayAndObject();

export default {
  name: "EditBanner",
  components: { DatePicker },
  data(){
    return {
      banner: {
        id: 0,
        name: '',
        codename: '',
        img_url: '',
        file: [],
        showStart: '',
        showEnd: '',
        type: '',
        url: '',
        action: '',
        placement: '',
        is_on: 0
      },
      submitted: false,
    }
  },
  props: {
    setDefault: Object,
    submit: Number
  },
  watch: {
    setDefault: function(val){
      this.banner.id = val.id;
      this.banner.name = val.name;
      this.banner.codename = val.codename;
      this.banner.img_url = val.img_url;

      this.banner.showStart = formatter.phpDatetimeToJsObject(val.start_dt);
      this.banner.showEnd = formatter.phpDatetimeToJsObject(val.stop_dt);
      this.banner.type = val.type;
      this.banner.url = val.url;
      this.banner.action = val.action;
      this.banner.placement = val.placement;
      this.banner.is_on = val.is_on;
    },
    submit: function(val){
      if(val > 0){
        this.submitCreate();
      }
    }
  },
  computed: {

  },
  validations: {
    banner: {
      name: { required, minLength: minLength(3), maxLength: maxLength(255) },
      codename: { required, minLength: minLength(3), maxLength: maxLength(60) },
      type: { required, minLength: minLength(3), maxLength: maxLength(60) },
      placement: { required, minLength: minLength(3), maxLength: maxLength(60) },
    }
  },
  methods: {
    previewFiles(event) {
      this.banner.file = event.target.files;
    },
    submitCreate(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.banner.$touch();
      if(!this.$v.banner.$invalid){
        this.$emit('submitCreate', this.banner);
      }
    },
  },
  created() {

  }

};
</script>

<style scoped lang="css">

</style>
